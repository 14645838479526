import React, { forwardRef } from "react";

const InputClasses = "db w-100 pv2 border-box bg-transparent bn";

export const Input = forwardRef(function (
  { type, className: classNameProp, ...props },
  ref
) {
  const className = `${InputClasses} ${classNameProp || ""}`;

  const ComponentProps = { ref, className, ...props };

  let Tag = "input";

  switch (type) {
    case "select":
      Tag = "select";
      break;
    case "textarea":
      Tag = "textarea";
      break;
    default:
      ComponentProps.type = type;
      break;
  }

  return (
    <div className="b--moon-gray bb">
      <Tag {...ComponentProps} />
    </div>
  );
});

export default Input;
