import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Anchor, Button, HomeTitle } from "../components";
import { Slide } from "react-slideshow-image";
import ContactForm from "../form";
import "react-slideshow-image/dist/styles.css";
import { useSettings } from "../hooks";

const Slides = gql`
  query Slides {
    slides(last: 10) {
      nodes {
        id
        title
        slideFields {
          subtext
          buttonLink
          buttonLabel
        }
        featuredImage {
          node {
            sourceUrl: mediaItemUrl
          }
        }
      }
    }
  }
`;

const Animate = ({ className, ...props }) => {
  return <div className={`${className || ""} animate__animated`} {...props} />;
};

const Out = ({ className, ...props }) => {
  return <div className={`${className || ""} o-0`} {...props} />;
};

const SlideContainer = ({
  className = "",
  title,
  slideFields,
  featuredImage,
  indexes: { index, nextIndex, previousIndex },
}) => {
  const [animated, setAnimated] = useState({ component: Out });

  useEffect(() => {
    if (index === 0 && nextIndex === null && previousIndex === null) {
      setAnimated({ component: Animate });
    } else if (index === nextIndex) {
      setAnimated({ component: Animate });
    } else {
      setAnimated({ component: Out });
    }
  }, [nextIndex, previousIndex, index]);

  const props = { className, style: {} };
  if (featuredImage) {
    props.style.backgroundImage = `url("${featuredImage.node.sourceUrl}")`;
    props.className += " bg-center cover";
  }

  let { subtext, buttonLink, buttonLabel } = slideFields || {};

  const text = title ? title.split(".").join(".<br/>") : "";

  return (
    <div {...props}>
      <div className="w-100 mw9 center relative z-3">
        <animated.component className="animate__fadeIn tc">
          <div className="relative z-1 dib">
            <div className="pa3 pa5-l white lh-title relative z-2 dib">
              <div
                className="f2 f1-m f-headline-l"
                dangerouslySetInnerHTML={{ __html: text }}
              />
              {subtext && <div className="f3-l f4">{subtext}</div>}
            </div>
            <div className="z-1 absolute absolute--fill bg-primary o-80 dn db-l" />
          </div>
          {buttonLabel && (
            <div className="ph3 ph0-l mt3">
              <Button className="f2-l" inverted>
                <Anchor href={buttonLink}>{buttonLabel}</Anchor>
              </Button>
            </div>
          )}
        </animated.component>
      </div>
    </div>
  );
};

export const Hero = ({ className, background, logo, formTitle, loading }) => {
  const [previousIndex, setPreviousIndex] = useState(null);
  const [nextIndex, setNextIndex] = useState(null);
  const { customLogoSrc } = useSettings();

  const { data } = useQuery(Slides, { errorPolicy: "all" });
  const slides = data ? data.slides?.nodes || [] : [];

  const props = {
    style: {},
    className: "absolute-l absolute--fill-l z-1",
  };

  if (background) {
    props.style.backgroundImage = `url("${background}")`;
    props.className += " bg-center cover";
  }

  const autoplay = slides.length > 0;

  return (
    <div {...{ className }}>
      <div className="vh-100-l relative z-1">
        <div {...props}>
          <div className="vh-100-l w-50-l flex-l items-center-l pa4 pa0-l">
            <div className="w-100">
              {(logo || customLogoSrc) && (
                <div
                  className={`${!logo && "bg-white"} br2 pa2 center mw6 mb4`}
                >
                  <img
                    src={logo || customLogoSrc}
                    alt="Site Logo"
                    className="db mw-100"
                  />
                </div>
              )}
              <Slide
                onChange={(p, n) => {
                  setNextIndex(n);
                  setPreviousIndex(p);
                }}
                className="w-100"
                duration={8000}
                arrows={false}
                pauseOnHover={false}
                canSwipe={false}
                {...{ autoplay }}
              >
                {slides.map((node, index) => (
                  <SlideContainer
                    key={node.id}
                    className="w-100"
                    {...node}
                    indexes={{ index, nextIndex, previousIndex }}
                  />
                ))}
              </Slide>
            </div>
          </div>
          <div className="absolute-l top-0-l right-0-l vh-100-l w-50-l flex-l items-center-l">
            <div className="pa4 bg-white center shadow-4 mw6 w-100">
              <HomeTitle underline className="mb3">
                {!formTitle && !loading
                  ? "Make an Appointment"
                  : formTitle || ""}
              </HomeTitle>
              <ContactForm
                buttonClassName="pointer w-100 tc bg-primary white br2 pv3 f4"
                buttonLabel="Start"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
