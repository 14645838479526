import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../Context";

function PreloadWrap({ uri, children }) {
  const { preloader } = useAppContext();

  useEffect(() => {
    if (preloader.current.addUrl) {
      preloader.current.addUrl(uri);
    }
  }, [uri, preloader]);

  return children;
}

export const Anchor = (p) => {
  const { ...props } = p;

  let origin = null;

  if (!props.className) {
    props.className = "no-underline color-inherit";
  }

  if (props.href) {
    if (props.href.indexOf("/") === 0 || props.href.indexOf("#") === 0) {
      origin = process.env.REACT_APP_DOMAIN;
      props.href = origin + props.href;
    } else {
      ({ origin } = new URL(props.href));
    }

    if (props.href.indexOf("#") !== -1 && props.href.indexOf(origin) === 0) {
      const hash = props.href.substring(
        props.href.indexOf("#") + 1,
        props.href.length
      );

      const prevOnClick = props.onClick ? props.onClick : null;
      const element = document.getElementById(hash);

      props.onClick = (e) => {
        if (prevOnClick) {
          prevOnClick(e);
        }

        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };
    }
  }

  if (origin.indexOf(process.env.REACT_APP_DOMAIN) === 0) {
    let to = props.href.replace(origin, "");

    if (to.indexOf("#") === 0) {
      to = "/" + to;
    }

    return (
      <PreloadWrap uri={to}>
        <Link {...{ to }} {...props} href={null} />
      </PreloadWrap>
    );
  }

  if (!props.target && props.href.indexOf("tel") !== 0) {
    props.target = "_new";
  }

  if (!props.rel) {
    props.rel = "noopen nofollow";
  }

  // eslint-disable-next-line
  return <a {...props} />;
};

export default Anchor;
