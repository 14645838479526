import React from "react";

export const Youtube = ({ youtubeEmbed }) => {
  return (
    <div className="aspect-ratio aspect-ratio--16x9 bg-black">
      <iframe
        title="video placeholder"
        className="aspect-ratio--object"
        src={`${youtubeEmbed}?feature=oembed`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};
