import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSettings } from "../hooks";

export const Logo = ({
  className = "",
  initial = "w3-l",
  hover = "hover-disabled",
}) => {
  const [animateClass, setAnimateClass] = useState("");
  const { generalSettingsTitle, customLogoSrc } = useSettings();

  const props = {
    className: "no-underline color-inherit flex-l items-center-l",
  };
  props.className += ` ${hover}`;
  props.onMouseEnter = () => setAnimateClass("animate__animated animate__tada");
  props.onMouseLeave = () => setAnimateClass("");

  const imgSpanProps = {
    className: `db center w-50 w-auto-l tc ${className || ""} ${animateClass}`,
  };

  return (
    <div className="tc tl-l">
      {!!customLogoSrc && (
        <Link {...props} to="/">
          <span className={`db w-100 ${initial} flex-none`}>
            <span {...imgSpanProps}>
              <img src={customLogoSrc} alt={generalSettingsTitle} />
            </span>
          </span>
          <span className="nowrap-l db mt2 mt0-l">{generalSettingsTitle}</span>
        </Link>
      )}
    </div>
  );
};
