import React from "react";
import { Button, HomeTitle, Image } from "../components";

const ImageBlk = ({ sourceUrl }) => {
  return (
    <div className="w-50-l dn db-l relative z-1">
      <Image
        container="div"
        src={sourceUrl}
        className="cover bg-center absolute--fill absolute"
      />
    </div>
  );
};

export const Blocks = ({
  className,
  image,
  label,
  title,
  content,
  imagePosition = "left",
  uri,
}) => {
  return (
    <div {...{ className }}>
      <div className="flex-l items-stretch-l">
        {imagePosition === "left" && <ImageBlk {...(image || {})} />}
        <div className="w-50-l ph3 pa6-l">
          <HomeTitle underline className="mb3">
            {label}
          </HomeTitle>
          <div className="mv3">
            <div className="f4 lh-title mb3">{title}</div>
            <div className="lh-copy">{content}</div>
          </div>
          <Button href={uri}>learn more</Button>
        </div>
        {imagePosition === "right" && <ImageBlk {...(image || {})} />}
      </div>
    </div>
  );
};
