import React from "react";

const isWebpSupported = () => {
  const elem =
    typeof document === "object" ? document.createElement("canvas") : {};
  return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
};

export const Image = (props) => {
  let {
    src,
    webp,
    width,
    height,
    alt,
    container: Container,
    children,
    ...attr
  } = props;

  if (src?.sourceUrl) {
    if (src?.webp) {
      webp = src.webp;
    }

    src = src.sourceUrl;
  }

  if (width) {
    if (Number.isInteger(width)) {
      width = `${width}px`;
    }

    attr = { ...attr, width };
  }

  if (height) {
    if (Number.isInteger(height)) {
      height = `${height}px`;
    }
    attr = { ...attr, height };
  }

  if (Container) {
    attr.style = {
      ...attr.style,
      backgroundImage: `url("${isWebpSupported() && webp ? webp : src}")`,
    };
    return <Container {...attr}>{children}</Container>;
  }

  if (!alt) {
    alt = src;
  }

  const ImgTag = <img src={src} alt={alt} {...attr} />;

  if (!webp) {
    return ImgTag;
  }

  return (
    <picture>
      <source srcSet={webp} type="image/webp" />
      {ImgTag}
    </picture>
  );
};
