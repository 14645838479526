import { useQuery, gql } from "@apollo/client";
import { useMemo } from "react";

const query = gql`
  query LocationQuery {
    locations {
      nodes {
        label: title
        id
        fields: locationFields {
          lat: latitude
          lng: longitude
          footer
        }
      }
    }
  }
`;

export const useLocations = () => {
  const { data, loading, error } = useQuery(query, {
    errorPolicy: "all",
  });

  const locations = useMemo(() => (data ? data.locations.nodes || [] : []), [
    data,
  ]);

  const Points = useMemo(
    () =>
      locations.map((node) => {
        const _n = { ...node, fields: { ...node.fields } };
        _n.fields.lat = parseFloat(node.fields.lat);
        _n.fields.lng = parseFloat(node.fields.lng);
        return _n;
      }) || [],
    [locations]
  );

  return {
    Points,
    locations,
    loading,
    error,
  };
};
