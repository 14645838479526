import React from "react";
import { HomeTitle } from "../components";

export const Experience = ({ className, children, title, background }) => {
  const props = { style: {}, className: `relative z-1 pv6 ${className || ""}` };

  if (background) {
    props.style.backgroundImage = `url("${background}")`;
    props.className += " bg-bottom cover";
  }

  return (
    <div {...props}>
      <div className="tc relative z-2">
        <HomeTitle underline>{title}</HomeTitle>
        <div className="mv4 mw7 center lh-copy">{children}</div>
      </div>
      {background && (
        <div className="absolute absolute--fill z-1 bg-white o-50" />
      )}
    </div>
  );
};
