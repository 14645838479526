import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { PostContent } from "../node";
import { Anchor, HomeTitle, PageWidth } from "../components";
import { ReadMore } from "../svg";

const ServiceQuery = gql`
  query Services {
    services(last: 3) {
      nodes {
        id
        title
        content
        serviceFields {
          readMoreLink
          svg
        }
      }
    }
  }
`;

const ServiceAnimationClassName = "animate__animated animate__zoomIn";

const ServiceCard = ({
  className,
  last,
  title,
  content,
  serviceFields,
  hideLinks,
  index,
}) => {
  const [delayClassName, setDelayClassName] = useState("o-0");
  const uri = serviceFields?.readMoreLink;

  useEffect(() => {
    const timeout = setTimeout(
      () => setDelayClassName(ServiceAnimationClassName),
      300 * index
    );

    return () => clearTimeout(timeout);
  }, [index]);

  return (
    <div {...{ className }}>
      <div className="tc">
        {!!serviceFields?.svg && (
          <div
            className={`br-100 bg-primary h4 w4 center flex items-center ${delayClassName}`}
          >
            <div
              className="fill-white h3 w-100 center max-h-svg"
              dangerouslySetInnerHTML={{ __html: serviceFields.svg }}
            />
          </div>
        )}
        <div className="mb4 h4-l flex-l items-center-l">
          <HomeTitle>{title}</HomeTitle>
        </div>
        <PostContent trim className="f5 lh-copy clamp-1">
          {content}
        </PostContent>
        {uri && !hideLinks && (
          <Anchor href={uri}>
            <ReadMore
              width={150}
              className="fill-primary hover-fill-secondary"
            />
          </Anchor>
        )}
      </div>
      {!last && <div className="db dn-l b--primary bb bw2 w4 center mb5 mt4" />}
    </div>
  );
};

export const Services = ({ className, hideLinks }) => {
  const { data } = useQuery(ServiceQuery, { errorPolicy: "all" });
  const services = data ? data.services.nodes || [] : [];

  return (
    <div {...{ className }}>
      <PageWidth>
        <div className="flex-l nl4-l nr4-l nt4-l nb4-l">
          {services.map((service, index) => (
            <ServiceCard
              key={service.id}
              className="w-third-l w-100 pa4-l"
              last={index === services.length - 1}
              {...{ hideLinks, index }}
              {...service}
            />
          ))}
        </div>
      </PageWidth>
    </div>
  );
};
