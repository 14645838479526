import React from "react";
import { Route, Switch } from "react-router";

export function Main({ children }) {
  return (
    <div className="main lh-copy relative z-1 flex-auto">
      <Switch>
        <Route exact path="/">
          {children}
        </Route>
        <Route>
          <div className="mb4">{children}</div>
        </Route>
      </Switch>
    </div>
  );
}

export default Main;
