import React from "react";

export const Video = ({ mediaItemUrl }) => {
  return (
    <div className="aspect-ratio aspect-ratio--16x9 bg-black">
      <video
        src={mediaItemUrl}
        className="aspect-ratio--object"
        type="video/mp4"
        controls
      />
    </div>
  );
};
