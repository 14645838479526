import React, { useContext } from "react";
import Submenu from "./Submenu";
import { Anchor } from "../components";
import { ReactComponent as Carat } from "./carat.svg";
import { useLocation } from "react-router-dom";
import { useMenuContext } from "./MenuContext";
import { Star } from "../svg";
import { NavigationContext } from "../layout";

export function MenuItem({ setHover, hover, className, item, subMenu = {} }) {
  const { pathname } = useLocation();
  const { isDesktop } = useMenuContext();

  const DivProps = { className: "relative tc", key: item.id };

  const AnchorProps = {
    className,
    onClick: () => setHover(item.id),
  };

  if (isDesktop) {
    AnchorProps.onMouseEnter = () => setHover(item.id);
  }

  const SpanProps = {
    className: "db",
    item,
    active:
      process.env.REACT_APP_DOMAIN + pathname === item.url || hover === item.id,
  };

  if (
    process.env.REACT_APP_DOMAIN + pathname === item.url ||
    hover === item.id
  ) {
    AnchorProps.className += " white";

    if (isDesktop) {
      AnchorProps.className += " white bg-primary";
    }
  } else {
    if (isDesktop) {
      AnchorProps.className += " color-inherit bg-white";
    } else {
      AnchorProps.className += " light-gray white";
    }
  }

  if (item.cssClasses?.length > 0) {
    SpanProps.className += " " + item.cssClasses.join(" ");
  }

  if (hover === item.id) {
    DivProps.className += " z-2";
  } else {
    DivProps.className += " z-1";
  }

  const hasChildren = ~~item.childItems?.nodes?.length > 0;

  return (
    <div {...DivProps}>
      <div className="flex items-center">
        <Anchor href={item.url} {...AnchorProps}>
          <Inner {...SpanProps}>{item.label}</Inner>
        </Anchor>
        {!isDesktop && hasChildren ? (
          <span
            className="ml-auto db pa1 pointer"
            onClick={() => setHover(item.id)}
          >
            <Carat />
          </span>
        ) : null}
      </div>

      {hasChildren && hover === item.id ? (
        <Submenu items={item.childItems.nodes} {...subMenu} />
      ) : null}
    </div>
  );
}

function Inner({ item: source, active, ...props }) {
  const { location } = useMenuContext();

  if (location !== "HEADER_MENU") {
    return <span {...props} />;
  }

  const { children } = props;
  const iconReplacement = source?.icon?.img?.sourceUrl ? (
    <Wrap
      icon={
        <img
          src={source.icon.img.sourceUrl}
          alt="menu item icon"
          className="menu-item-img"
        />
      }
    >
      {children}
    </Wrap>
  ) : (
    <Wrap icon={<Svg icon={source?.icon?.svg} />}>{children}</Wrap>
  );

  return (
    <span className={`${props.className || ""} ${active ? "active" : ""}`}>
      {iconReplacement}
    </span>
  );
}

function Wrap({ children, icon }) {
  const { initial, hover } = useContext(NavigationContext);

  return (
    <div className={`${hover || ""} flex items-center`}>
      <div className={initial}>{icon}</div>
      <div>{children}</div>
    </div>
  );
}

function Svg({ icon }) {
  const { isDesktop } = useMenuContext();
  return icon ? (
    <div
      className={`menu-item-svg ${isDesktop ? "" : "is-mobile"}`}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  ) : (
    <div className={`menu-item-svg ${isDesktop ? "" : "is-mobile"}`}>
      <Star />
    </div>
  );
}

export default MenuItem;
