import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Main, Navigation, Locations } from "./layout";
import { Home } from "./home";
import Footer from "./footer";
import { AuthenticationRouting, LoginPathnames } from "./auth";
import Node from "./node";
import Search from "./search";
import "./scss/app.scss";
import { useSettings } from "./hooks";
import { useAppContext } from "./Context";

export function App() {
  const { root } = useAppContext();
  const { phoneNumber } = useSettings();
  const { search } = useLocation();
  const previewId = new URLSearchParams(search).get("p");

  return (
    <div
      className="min-vh-100 flex-l items-stretch-l fw3 black relative z-1 sans-serif"
      ref={root}
    >
      <Navigation initalWidth={3} hoverWidth={5} />

      <div className="min-vh-100 flex items-stretch flex-column w-100 relative z-1 overflow-hidden">
        <div className="fixed-l top-0-l right-0-l z-2 tc">
          <div className="ma3">
            {phoneNumber && (
              <div className="br-pill bg-primary pa3 mb3 f3 white animate__animated animate__fadeIn lh-solid">
                <a
                  href={`tel:+1${phoneNumber.replace(/\D/g, "")}`}
                  className="color-inherit no-underline"
                >
                  {phoneNumber}
                </a>
              </div>
            )}
          </div>
        </div>
        <Main>
          <Switch>
            <Route exact path="/">
              {previewId ? <Node databaseId={previewId} /> : <Home />}
            </Route>

            <Route exact path="/contact-us">
              <Locations />
            </Route>

            <Route exact path={LoginPathnames}>
              <AuthenticationRouting />
            </Route>

            <Route exact path="/search">
              <Search />
            </Route>

            <Route path="*">
              <Node />
            </Route>
          </Switch>
        </Main>
        <Footer />
      </div>
    </div>
  );
}

export default App;
