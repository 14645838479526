import { Helmet } from "react-helmet-async";

export function Development() {
  return process.env.NODE_ENV === "development" ? (
    <Helmet>
      <link
        href={`${process.env.REACT_APP_DOMAIN}/wp-includes/css/dist/block-library/style.min.css`}
        rel="stylesheet"
      />
      <link
        href={`${process.env.REACT_APP_DOMAIN}/wp-includes/css/dist/block-library/theme.min.css`}
        rel="stylesheet"
      />
    </Helmet>
  ) : null;
}

export default Development;
