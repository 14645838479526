import React, { createContext, useContext } from "react";
import { Button } from "../components";
import { gql } from "@apollo/client";
import Field from "./Field";
import Input from "./Input";
import Recaptcha from "./Recaptcha";
import Textarea from "./Textarea";
import useForm from "./useForm";
import Valid from "./Valid";
import Upload from "./Upload";

const FormMutation = gql`
  mutation FormMutation($input: DefaultFormMutationInput!) {
    defaultFormMutation(input: $input) {
      success
      errorMessage
      clientMutationId
    }
  }
`;

function FormRender({ className, children }) {
  const { fields, token, onClick, error, success, loading, files, setFiles } =
    useContext(FormContext);

  return (
    <div {...{ className }}>
      <div>
        {error && <div className="bg-near-white f4 red pa3 mb3">{error}</div>}
        {success && (
          <div className="bg-near-white f4 green pa3 mb3">{success}</div>
        )}
      </div>
      <div className="overflow-hidden">
        {!children ? (
          <>
            <Field id="yourName" label="Name" required>
              <Input
                ref={fields}
                errorMessage="Provide a name."
                valid={Valid.NotEmptyString}
              />
            </Field>
            <div className="flex-l nl3-l nr3-l">
              <div className="w-50-l ph3-l">
                <Field id="email" label="Email" required>
                  <Input
                    ref={fields}
                    type="email"
                    errorMessage="Must be an email."
                    valid={Valid.Email}
                  >
                    <Recaptcha {...{ token }} />
                  </Input>
                </Field>
              </div>
              <div className="w-50-l ph3-l">
                <Field id="phone" label="Phone">
                  <Input ref={fields} type="tel" />
                </Field>
              </div>
            </div>
            <Field id="message" label="Message" required>
              <Textarea
                ref={fields}
                errorMessage="State the nature of your inquiry."
                valid={Valid.NotEmptyString}
              />
            </Field>
          </>
        ) : (
          children
        )}

        <Upload
          label="Click Here to upload an image of your recycling (optional)"
          accept="image/*"
          {...{ files }}
          onDrop={(f) => {

            setFiles(f?.length > 0 ? f : []);
          }}
        />

        <div className="tc tr-l mt3">
          <Button {...{ onClick, loading }} form>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

export function Form({ mutation = FormMutation, ...props }) {
  const value = useForm({
    mutation,
  });

  return (
    <FormContext.Provider {...{ value }}>
      <FormRender {...props} />
    </FormContext.Provider>
  );
}

export const FormContext = createContext({});

export default Form;
