import React from "react";
import { PageWidth, Title } from "../components";
import PostContent from "./PostContent";
import SinglePost from "./SinglePost";
import { Services } from "../home/Services";
import { ServiceForm } from "../form";

export function Single(props) {
  const { node, className } = props;
  const { title, content, name, __typename, template } = node;

  if (template?.templateName === "Service Form") {
    return (
      <>
        <Title>{title}</Title>
        <PageWidth {...{ className }}>
          <Services hideLinks className="mb6" />
          <PostContent className="mb4">{content}</PostContent>
          <ServiceForm />
        </PageWidth>
      </>
    );
  }

  if (__typename === "Post") {
    return <SinglePost {...props} />;
  }

  return (
    <>
      <Title>{title || name}</Title>
      <PageWidth>
        <PostContent>{content}</PostContent>
      </PageWidth>
    </>
  );
}

export default Single;
