import { gql, useQuery } from "@apollo/client";

export function useSettings() {
  const { data, loading, error } = useQuery(SettingsQuery, {
    errorPolicy: "all",
  });

  const themeMods = data?.themeMods || {};
  const allSettings = data?.allSettings || {};

  return {
    ...allSettings,
    ...themeMods,
    loading,
    error,
  };
}

const SettingsQuery = gql`
  query SettingsQuery {
    allSettings {
      generalSettingsTitle
      generalSettingsDescription
    }
    themeMods {
      customLogoSrc
      phoneNumber
      gmapsApi
    }
  }
`;

export default useSettings;
