import React, { useEffect, useRef, useState } from "react";
import MenuItem from "./MenuItem";
import useMenu from "./useMenu";
import { useLocation } from "react-router-dom";
import { ReactComponent as Bars } from "./bars.svg";
import { MenuProvider, useMenuContext } from "./MenuContext";
import { useAppContext } from "../Context";

const hasAdminBar = !!document.getElementById("wpadminbar");

function MenuRender({ location }) {
  const [open, setOpen] = useState();
  const [hover, setHover] = useState("");
  const prevPath = useRef();
  const { root } = useAppContext();
  const { isDesktop, menuBar } = useMenuContext();
  const { pathname } = useLocation();
  const { menuItems } = useMenu({ location });

  const DrawerBackground = {
    id: "drawer-background",
    className: "relative-l z-5",
    style: {},
  };
  const MobileDrawer = { id: "mobile-drawer", className: "", style: {} };

  /**
   * Closes menu on path change.
   */
  useEffect(() => {
    if (pathname !== prevPath.current) {
      setOpen(false);
    }
  }, [pathname]);

  /**
   * Updates the prevPath when pathname changes.
   */
  useEffect(() => {
    prevPath.current = pathname;
  }, [pathname]);

  if (open) {
    DrawerBackground.className += " db";
  } else {
    DrawerBackground.className += " dn db-l";
  }

  if (!isDesktop) {
    DrawerBackground.className +=
      " bg-white-30 absolute absolute--fill pointer";

    if (hasAdminBar) {
      DrawerBackground.style.top = "46px";
    }

    MobileDrawer.className += " bg-dark-gray white pa3 min-h-100";
    MobileDrawer.style.width = "50vw";
    MobileDrawer.style.cursor = "default";

    if (open) {
      MobileDrawer.className += " animate__animated animate__slideInLeft";
    }

    DrawerBackground.onClick = () => {
      setOpen(false);
    };

    MobileDrawer.onClick = (event) => {
      event.stopPropagation();
    };

    if (root?.current) {
      DrawerBackground.style.height = `${
        root.current.getBoundingClientRect().height
      }px`;
    }
  } else {
    DrawerBackground.onMouseLeave = () => setHover("");
  }

  return (
    <div>
      <div className="db dn-l bg-near-black pa2 mb2" ref={menuBar}>
        <div className="flex items-center">
          <div className="pointer" onClick={() => setOpen(!open)}>
            <Bars />
          </div>
        </div>
      </div>
      <div className="flex-l items-center-l justify-between-l w-100">
        <div {...DrawerBackground}>
          <div {...MobileDrawer}>
            <div className="ma0">
              {menuItems.map((item, index) => (
                <MenuItem
                  key={item.id}
                  className="db no-underline pv3 b--primary bt-l"
                  subMenu={{ className: "nt3-l" }}
                  {...{ item, hover, setHover, index }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Menu(props) {
  return (
    <MenuProvider location={props.location}>
      <MenuRender {...props} />
    </MenuProvider>
  );
}

export default Menu;
