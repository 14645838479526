import React, { useContext } from "react";
import { gql } from "@apollo/client";
import Form, { FormContext } from "./Form";
import Input from "./Input";
import Field from "./Field";
import Textarea from "./Textarea";
import Recaptcha from "./Recaptcha";
import Valid from "./Valid";
import Checkbox from "./Checkbox";

function ServiceFields() {
  const { fields, token } = useContext(FormContext);

  return (
    <>
      <Field id="firstName" label="First Name" className="w-50-l fl-l pr2-l cb">
        <Input ref={fields} />
      </Field>
      <Field
        id="lastName"
        label="Last Name"
        required
        className="w-50-l pl1-l fl-l"
      >
        <Input
          ref={fields}
          errorMessage="Provide a last name."
          valid={Valid.NotEmptyString}
        />
      </Field>

      <Field id="orgName" label="Organization Name" className="cb">
        <Input ref={fields} />
      </Field>

      <div className="flex-l nl3-l nr3-l">
        <div className="w-50-l ph3-l">
          <Field id="email" label="Email" required>
            <Input
              ref={fields}
              type="email"
              errorMessage="Must be an email."
              valid={Valid.Email}
            >
              <Recaptcha {...{ token }} />
            </Input>
          </Field>
        </div>
        <div className="w-50-l ph3-l">
          <Field id="phone" label="Phone">
            <Input
              ref={fields}
              type="tel"
              error="Invalid phone number."
              valid={(v) => Valid.Phone(v) || v === ""}
            />
          </Field>
        </div>
      </div>

      <Field id="location" label="Location">
        <Input ref={fields} />
      </Field>

      <Field id="message" label="How Can We Help?">
        <Textarea ref={fields} rows={7} />
      </Field>

      <Field id="client" label="New or Existing Client">
        <Checkbox
          type="radio"
          ref={fields}
          options={[
            { value: "New", label: "New" },
            { value: "Existing", label: "Existing" },
          ]}
        />
      </Field>

      <Field id="hear" label="How did you hear about us?">
        <Textarea ref={fields} rows={7} />
      </Field>
    </>
  );
}

export function ServiceForm() {
  return (
    <Form mutation={ServiceMutation}>
      <ServiceFields />
    </Form>
  );
}

const ServiceMutation = gql`
  mutation ServiceForm($input: ServiceFormMutationInput!) {
    serviceFormMutation(input: $input) {
      clientMutationId
      success
      errorMessage
    }
  }
`;

export default ServiceForm;
