import React from "react";
import { useDropzone } from "react-dropzone";

/* eslint-disable jsx-a11y/alt-text */
export function Upload({ files, className, label, ...props }) {
  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 5242879,
    ...props,
  });

  const rootProps = getRootProps({
    className: `bg-near-white b--moon-gray ba pa2 f6 near-black mb3 relative z-1 pointer tc overflow-hidden ${
      className || ""
    }`,
  });

  return (
    <div {...rootProps}>
      <input {...getInputProps()} />
      <div className="pa4 pointer br2 f4">
        {label || "Drag 'n' drop some files here, or click to select files"}
      </div>
      {~~files?.length > 0 && (
        <div>
          {files.map((file) => {
            if (file.name) {
              const preview = file.type.includes("image")
                ? URL.createObjectURL(file)
                : null;

              return (
                <div
                  key={file.name + file.lastModified}
                  className="flex items-center justify-between mv3"
                >
                  <div>{file.name}</div>
                  {preview && (
                    <img src={preview} className="w3 w4 object-cover" />
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
    </div>
  );
}

export default Upload;
