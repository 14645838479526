import React from "react";

export function Title(p) {
  const { notHeading, className = "", children } = p;
  const Wrap = notHeading ? "div" : "h1";

  return (
    <div {...{ className }}>
      <Wrap className="bg-primary mt0 mb5 white pv3 pv6-l tc f-headline-l f2 lh-title">
        {children}
      </Wrap>
    </div>
  );
}

export default Title;
