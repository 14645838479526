import React from "react";
import { useQuery, gql } from "@apollo/client";
import { PageWidth, Anchor } from "../components";
import { useLocations, useSettings } from "../hooks";

export function Footer() {
  const { generalSettingsTitle, generalSettingsDescription } = useSettings();
  const { locations } = useLocations();

  return (
    <footer id="footer" className="bg-near-white">
      <div>
        <PageWidth>
          <div className="flex-l pv4">
            <FooterColumn>
              <div className="f3 mb2">{generalSettingsTitle}</div>
              <div>{generalSettingsDescription}</div>
            </FooterColumn>
            <FooterColumn className="ml-auto-l tr-l">
              <nav>
                <FlatMenu
                  location="FOOTER_MENU"
                  className="pl0 list lh-copy f7 ma0"
                />
              </nav>
              <div>
                {locations.map((node) => {
                  return node.fields.footer ? (
                    <div className="mv2" key={node.id}>
                      {node.label}
                    </div>
                  ) : null;
                })}
              </div>
            </FooterColumn>
          </div>
        </PageWidth>
      </div>
      <div className="f7 pv4">
        <PageWidth>
          <div className="flex-l items-center-l">
            <div>
              Copyright {new Date().getFullYear()}{" "}
              {generalSettingsTitle ? ` - ${generalSettingsTitle}` : "..."}
              <span> | All rights reserved</span>
            </div>

            <div className="ml-auto-l"></div>
          </div>
        </PageWidth>
      </div>
    </footer>
  );
}

const FooterMenu = gql`
  query FooterMenu {
    menuItems(where: { location: FOOTER_MENU }) {
      nodes {
        id
        url
        label
        connectedNode {
          node {
            __typename
          }
        }
      }
    }
  }
`;

function FooterColumn({ children, className = "" }) {
  return <div className={`w-100 ${className}`}>{children}</div>;
}

function MenuLink({ url, label }) {
  const props = { className: "color-inherit no-underline dib mh2 f7" };

  props.href = url;

  return <Anchor {...props}>{label}</Anchor>;
}

function FlatMenu() {
  const { data } = useQuery(FooterMenu);
  const menu = data?.menuItems?.nodes || [];

  return menu.map(({ id, url, label }) => (
    <MenuLink key={id} {...{ url, label }} />
  ));
}

export default Footer;
