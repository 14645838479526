import React, { useCallback } from "react";
import { useNode, Seo, PostContent } from "../node";
import { useLocations, useSettings } from "../hooks";
import { GoogleMap, OverlayView, LoadScript } from "@react-google-maps/api";
import { MapPin } from "../svg";
import { HomeTitle, PageWidth, Title } from "../components";
import ContactForm from "../form";

export function Locations({ className }) {
  const { node } = useNode({ mainQuery: true });
  const { Points } = useLocations();
  const { gmapsApi } = useSettings();

  const getMapBounds = useCallback(() => {
    const maps = window.google.maps;

    const bounds = new maps.LatLngBounds();

    Points.forEach((place) => {
      bounds.extend(new maps.LatLng(place.fields.lat, place.fields.lng));
    });

    return bounds;
  }, [Points]);

  const GoogleMapReactProps = {
    center: {
      lat: Points?.[0]?.fields?.lat || 0,
      lng: Points?.[0]?.fields?.lng || 0,
    },
    zoom: 17,
    mapContainerStyle: { width: "100%", height: "100%" },
    options: { gestureHandling: "none", clickableIcons: false },
    onLoad: (map) => {
      const maps = window.google?.maps;

      if (maps) {
        const bounds = getMapBounds();
        map.fitBounds(bounds);

        maps.event.addDomListenerOnce(map, "idle", () => {
          maps.event.addDomListener(window, "resize", () => {
            map.fitBounds(bounds);
          });
        });

        map.setOptions({ styles });
      }
    },
  };

  if (!Points || Points.length === 0) {
    return null;
  }

  return (
    <div>
      <Seo {...node?.seo} />
      <Title>{node.title}</Title>
      <PageWidth>
        <PostContent>{node.content}</PostContent>
      </PageWidth>
      <div className={gmapsApi ? "map-height" : className || ""}>
        {gmapsApi ? (
          <LoadScript googleMapsApiKey={gmapsApi}>
            <GoogleMap {...GoogleMapReactProps}>
              {Points.map((point) => (
                <Marker
                  lat={point.fields.lat}
                  lng={point.fields.lng}
                  key={point.id}
                />
              ))}
            </GoogleMap>
          </LoadScript>
        ) : null}
      </div>
      <PageWidth className="mt4">
        <div className="w-60-l center">
          <HomeTitle underline className="mb3">
            Contact Us
          </HomeTitle>
          <ContactForm />
        </div>
      </PageWidth>
    </div>
  );
}

function Marker({ lat, lng }) {
  return (
    <OverlayView
      position={{ lat, lng }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div className="relative z-1">
        <div className="relative z-1" style={{ width: "32px" }}>
          <MapPin className="fill-secondary absolute bottom-100 left-0 trans-x--50" />
        </div>
      </div>
    </OverlayView>
  );
}

const styles = [
  {
    featureType: "administrative.locality",
    elementType: "all",
    stylers: [
      { hue: "#2c2e33" },
      { saturation: 7 },
      { lightness: 19 },
      { visibility: "on" },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      { hue: "#ffffff" },
      { saturation: -100 },
      { lightness: 100 },
      { visibility: "simplified" },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      { hue: "#ffffff" },
      { saturation: -100 },
      { lightness: 100 },
      { visibility: "off" },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      { hue: "#bbc0c4" },
      { saturation: -93 },
      { lightness: 31 },
      { visibility: "simplified" },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      { hue: "#bbc0c4" },
      { saturation: -93 },
      { lightness: 31 },
      { visibility: "on" },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels",
    stylers: [
      { hue: "#bbc0c4" },
      { saturation: -93 },
      { lightness: -2 },
      { visibility: "simplified" },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      { hue: "#e9ebed" },
      { saturation: -90 },
      { lightness: -8 },
      { visibility: "simplified" },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      { hue: "#e9ebed" },
      { saturation: 10 },
      { lightness: 69 },
      { visibility: "on" },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      { hue: "#e9ebed" },
      { saturation: -78 },
      { lightness: 67 },
      { visibility: "simplified" },
    ],
  },
];
