import { gql } from "@apollo/client";
import React from "react";
import { PostContent, useNode, Seo, FragmentPage } from "../node";
import { Anchor, Button, PageWidth } from "../components";
import { Blocks } from "./Blocks";
import { Experience } from "./Experience";
import { Hero } from "./Hero";
import { Services } from "./Services";
import { Video } from "./Video";
import { Youtube } from "./Youtube";

export function Home() {
  const { node, acf, loading } = useNode({ mainQuery: true, query });
  const {
    callToAction = {},
    top = {},
    bottom = {},
    video = {},
    hero = {},
    form = {},
  } = acf?.homepage || {};
  const { text, label, buttonUrl, buttonLabel, background } =
    callToAction || {};

  return (
    <div>
      {node.seo && <Seo {...node.seo} />}

      <Hero
        background={hero?.background?.mediaItemUrl}
        logo={hero?.logo?.mediaItemUrl}
        formTitle={form?.title}
        {...{ loading }}
      />

      <div className="bg-black mb6-l mv4 mt0-l">
        <div className="mw8 center ph3">
          {video?.file?.mediaItemUrl ? (
            <Video {...video.file} />
          ) : video?.youtubeEmbed ? (
            <Youtube {...video} />
          ) : null}
        </div>
      </div>

      <Services className="mv6-l mv4 pv6-l pv4" />

      <Blocks
        className="mt6-l mt4 mb3 mb0-l"
        imagePosition="left"
        {...(top || {})}
      />
      <Blocks className="mb6-l mb4" imagePosition="right" {...(bottom || {})} />

      {node.content && (
        <PageWidth className="mv6-l mv4">
          <PostContent>{node.content}</PostContent>
        </PageWidth>
      )}

      {label && (
        <Experience title={label} background={background?.sourceUrl}>
          <div className="f4 mb4">{text}</div>
          <Button>
            <Anchor href={buttonUrl}>{buttonLabel}</Anchor>
          </Button>
        </Experience>
      )}
    </div>
  );
}

const query = gql`
  query HomepageNode($uri: String!) {
    node: nodeByUri(uri: $uri) {
      ...PageFragment
    }
    acfGroups(uri: $uri) {
      homepage {
        id
        hero {
          id
          background {
            mediaItemUrl
          }
          logo {
            mediaItemUrl
          }
        }
        form {
          title
        }
        bottom {
          id
          content
          label
          uri
          title
          image {
            sourceUrl(size: LARGE)
          }
        }
        callToAction {
          id
          label
          text
          buttonLabel
          buttonUrl
          background {
            sourceUrl(size: LARGE)
          }
        }
        top {
          id
          content
          label
          uri
          title
          image {
            sourceUrl(size: LARGE)
          }
        }
        video {
          id
          youtubeEmbed
          file {
            mediaItemUrl
          }
        }
      }
    }
  }
  ${FragmentPage}
`;
