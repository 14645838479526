import React from "react";

export const HomeTitle = ({ className, children, white, underline }) => {
  return (
    <div {...{ className }}>
      <div className={`fw7 f2 lh-title ${white ? "white" : ""}`}>
        {children}
      </div>
      {underline && <div className="dib w4 bb bw2 b--secondary mt3" />}
    </div>
  );
};
