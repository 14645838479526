import React from "react";
import { Menu } from "../menu";
import { Logo } from "../components";
import { NavigationContext } from "./NavigationContext";

const hasAdminBar = !!document.getElementById("wpadminbar");

export const Navigation = ({ initalWidth = 3, hoverWidth = 5 }) => {
  const initial = `w${initalWidth}-l`;
  const hover = `w${hoverWidth}-l`;
  const className = `${initial} hover-${hover} trans-w overflow-hidden navigation fixed-l top-0-l left-0-l bottom-0-l`;

  return (
    <NavigationContext.Provider value={{ initial, hover }}>
      <div
        className={`${initial} hover-${hover} relative trans-w z-5 flex-none bg-white shadow-4 no-change animate__animated animate__fadeIn`}
      >
        <nav {...{ className }}>
          {hasAdminBar && (
            <div className="dn db-l w1" style={{ height: "32px" }} />
          )}
          <div className="pv3">
            <Logo {...{ initial, hover }} className="pl3 pr3 pb3 pb0-l" />
          </div>

          <Menu location="HEADER_MENU" className={`db ma0 f6 ml-auto-l`} />
        </nav>
      </div>
    </NavigationContext.Provider>
  );
};
